import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  getters: {
    getCondition(state){
      return state.condition
    }
  },
  mutations: {},
  actions: {
    getstockcheckrealtime(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/bugfix/getstockcheckrealtime', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
